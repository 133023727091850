$block: '.recycle-form-modal';

#{$block} {
    & .input-label-wrapper {
        margin-bottom: 10px;
    }
}

.action-btns {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
