$block: '.form-modal';

#{$block} {
  min-width: 180px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 24px 24px 16px 24px;
    border-bottom: 1px solid #0000001a;

    & .title {
      font-size: 17px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.3px;
      color: #000000d9;
      margin-bottom: 4px;
    }

    & .description {
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      color: #00000080;
    }
  }

  &__content {
    padding: 24px;
  }

  &__actions {
    padding: 16px 24px 24px 24px;
    border-top: 1px solid #0000001a;
  }
}

.modal.g-modal {
  backdrop-filter: blur(10px);
  background-color: #0000008C;
}
