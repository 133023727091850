.input-label-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.input-label {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: #00000080;
    margin-bottom: 4px;
}
