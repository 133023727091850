* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.toaster {
  & .g-toast__title {
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    color: #000000D9;
  }

  & .g-toast__content {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: #000000B2;
  }

  & .success-green svg path {
    fill: #30AA6E;
  }

  & .success-red svg path {
    fill: #DC1847;
  }
}
