$sidePanelForm: '.side-panel-form';

#{$sidePanelForm} {
  flex-grow: 1;

  &__form-title {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #00000080;
    display: block;
    margin-bottom: 20px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .input-label-wrapper {
    margin-bottom: 10px;
  }
}
