$block: '.act-side-panel';

#{$block} {
  height: 100%;
  max-width: 450px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;

  &__header {
    width: 100%;
    height: 84px;
    border-bottom: 2px solid #f2f2f2;
    padding: 18px 24px;
  }

  &__header-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: #000000d9;
    margin-bottom: 8px;
    display: block;
  }

  &__datetime {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 28px;

    & .datetime-edit-btn svg path {
      fill: #00000080;
    }

    & .text {
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      color: #00000080;
    }
  }

  &__form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 18px 24px;

    & .row {
      display: flex;

      & * {
        margin-right: 10px;
      }

      & *:last-child {
        margin-right: 0;
      }

      & .input-label-wrapper {
        margin-bottom: 0;
      }
    }

    & .input-label-wrapper {
      margin-bottom: 20px;
    }

    & .g-select {
      width: 100%;
    }

    & .g-switch_checked .g-switch__indicator::before,
    .g-switch_checked:hover .g-switch__indicator::before {
      background-color: #ff003de5;
    }
  }

  &__acts-empty-title {
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: #000000d9;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  &__acts-empty-desc {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: #00000080;
    max-width: 300px;
    text-align: center;
    margin-bottom: 10px;
  }

  &__side-panel-empty {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
