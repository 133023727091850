$block: '.act-filter-popup-content';

#{$block} {
  min-width: 300px;
  padding: 24px 12px;

  & .input-label-wrapper {
    margin-bottom: 10px;
  }

  & .g-select {
    width: 100%;
  }

  & .g-switch_checked .g-switch__indicator::before,
  .g-switch_checked:hover .g-switch__indicator::before {
    background-color: #ff003de5;
  }

  &__popup-btns {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
  }

  &__tree-select {
    width: 100%;

    & .ant-select-selection-search {
      font-size: 13px;
    }
  }
}

.g-popup_open {
  z-index: 1300;
}