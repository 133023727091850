$block: '.acts-list-container';

#{$block} {
  position: relative;
  width: 100%;
  flex-grow: 1;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

  &__acts-list {
    width: 100%;
    // height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  &__act-skeleton.g-skeleton {
    position: absolute;
    width: unset;
    top: 32px;
    left: 32px;
    bottom: 32px;
    right: 32px;
    z-index: 1000 !important;
    background-color: #e4e4e4;
  }
}
