$block: '.act-card';

#{$block} {
  position: relative;
  width: 100%;
  max-width: 924px;
  min-height: 252px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid #0000001a;
  padding: 16px;
  border-radius: 12px;
  margin-bottom: 16px;
  overflow: hidden;
  cursor: pointer;

  &_selected {
    border-color: #dc1847;
    background-color: #fff7f9;
  }

  &__img-wrapper {
    position: relative;
    width: 220px;
    height: 220px;
    border-radius: 12px;
    overflow: hidden;
    margin-right: 16px;

    & .count {
      display: block;
      position: absolute;
      bottom: 8px;
      left: 8px;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      color: #ffffff;
      background-color: #ffffff26;
      border: 1px solid #ffffff66;
      padding: 4px 12px;
      border-radius: 16px;
      backdrop-filter: blur(10px);
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    & .checkbox {
      position: absolute;
      top: 8px;
      left: 8px;
    }

    & .checkbox.g-checkbox_checked .g-checkbox__indicator::before,
    .g-checkbox_indeterminate .g-checkbox__indicator::before {
      background-color: #ff003de5;
    }

    & .checkbox.g-checkbox_checked .g-checkbox__icon,
    .g-checkbox_indeterminate .g-checkbox__icon {
      color: #ffffff;
    }

    & .checkbox .g-checkbox__indicator::before {
      background-color: #ffffff4d;
      border-color: #ffffff66;
    }
  }

  &__act-info {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__title {
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.3px;
    margin-bottom: 20px;
  }

  &__row-info {
    width: 100%;
    border-bottom: 1px solid #0000001a;
    padding-bottom: 8px;
    padding-top: 8px;
    display: flex;

    & .name,
    & .value {
      display: inline-block;
      width: 150px;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
    }

    & .name {
      color: #00000080;
    }

    & .value {
      color: #000000d9;
      flex-grow: 1;
      display: flex;
      align-items: center;

      & svg {
        margin-left: 4px; 
        & path {
          fill: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  &__row-info:last-child {
    border: none;
  }

  &__card-btns {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &__saved-status-label.g-label {
    border-radius: 20px;
  }
}

#{$block}:last-child {
  margin-bottom: 0;
}
