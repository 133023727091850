$block: '.main-page';

#{$block} {
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: 100%;
  height: calc(100% - 66px);
  background-color: #f2f2f2;

  &__acts-nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 22px 32px;
    margin-bottom: 4px;
  }

  &__title {
    display: inline-block;
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.02em;
  }

  &__group-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__container-flex {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
  }

  &__container-flex-column {
    // height: calc(100% - 90px);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin-right: 4px;
  }

  &__acts-empty {
    width: 100%;
    height: 100%;
    border: 1px solid #0000001a;
    border-radius: 16px;
    background: url(../../assets/matrix.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__acts-empty-title {
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: #000000d9;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  &__acts-empty-desc {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: #00000080;
    max-width: 430px;
    text-align: center;
    margin-bottom: 10px;
  }
}
