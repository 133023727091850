$emptySidePanel: '.edit-date-popup';

#{$emptySidePanel} {
    min-width: 300px;
    padding: 12px;

    & .input-label-wrapper {
        margin-bottom: 10px;
    }

    & .g-select {
        width: 100%;
    }

    & .g-switch_checked .g-switch__indicator::before,
    .g-switch_checked:hover .g-switch__indicator::before {
        background-color: #ff003de5;
    }
}
