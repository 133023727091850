$block: '.take-acts-form-modal';

#{$block} {
	width: 100%;
	display: flex;
	justify-content: center;

  & .g-pin-input__item {
    height: 70px;
    width: 60px;

    & * {
      height: 100%;
    }

    & input {
      font-size: 48px;
      font-weight: 600;
    }
  }
}

.action-btns {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
