$emptySidePanel: '.side-panel-empty';

#{$emptySidePanel} {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__acts-empty-title {
        font-size: 17px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.3px;
        color: #000000d9;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    &__acts-empty-desc {
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        color: #00000080;
        max-width: 300px;
        text-align: center;
        margin-bottom: 10px;
    }
}
