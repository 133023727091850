$block: '.app-bar';

#{$block} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #ffffff;
    padding: 12px 32px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    margin-bottom: 4px;

    &__logo-wrapper {
        width: 112px;
        height: 28px;
    }

    &__logo-wrapper img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &__user-setting {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}
