.primary-button.g-button {
  background-color: #ff003de5;
  color: #ffffff;
  border-radius: 12px;

  &.g-button_disabled {
    background-color: #0000000d;
    color: #0000004d;
  }

  &.g-button_loading {
    background-color: #ff003de5;
  }
}

.primary-button:focus,
.primary-button:hover {
  color: #ffffff;
}
