$block: '.success-withdraw-modal';

#{$block} {
	width: 100%;
	display: flex;
  flex-direction: column;
	justify-content: flex-start;
  align-items: center;

  &__title {
    margin-top: 10px;
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: #000000D9;
  }

  &__desc {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.05px;
    color: #000000B2;
    max-width: 236px;
    text-align: center;
  }
}

.action-btns {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
