$block: '.act-counter';

#{$block} {
    position: fixed;
    bottom: 20px;
    width: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;

    &__container {
        min-height: 50px;
        width: 100%;
        max-width: 1080px;
        border-radius: 12px;
        background-color: #000000d9;
        padding: 12px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__counter-box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &__counter {
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        color: #ffffff;
        white-space: nowrap;
    }
}
